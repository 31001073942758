.admin-static {
  position: relative;
  margin-top: 45px;

  .warning {
    padding-right: 220px;
    span {
      color: red;
      margin-right: 5px;
    }
  }

  .translation-select-wrapper {
    display: flex;
    justify-content: flex-end;

    .input-group {
      width: 200px;
    }
  }

  .translation-tabs {
    margin-bottom: 10px;

    .privacy-policy-wrapper {
      .input-group {
        textarea {
          resize: vertical;
          height: calc(100vh - 250px);
        }
      }

      .text-area-view {
        height: calc(100vh - 250px);
      }
    }
  }
}
