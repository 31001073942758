@import "../mainColors";

.nestable-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .nestable-item-inner {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    height: 40px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: move;
    background: white;

    .moving-icon {
      margin-right: 5px;
      margin-left: 12px;

      i {
        font-size: 20px;
      }
    }

    .status {
      margin: 0 5px 0 12px;
      font-weight: 600;
    }

    .color {
      margin: 0 5px 0 12px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
    }

    .item-title, .item-description {
      flex-grow: 1;
      overflow: hidden;
      //width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        color: #333;
        font-size: 14px;
      }
    }

    .item-title {
      span {
        color: #333;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .action-buttons {
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    display: flex;
    margin: 0 12px 0 0px;

    .link-button {
      padding: 3px 6px;
      margin-left: 8px;
    }
    .edit-button{
      background: $brandColor;
    }
  }

  &.largeItem {
    .nestable-item-inner {
      height: 70px;
      width: 100%;
      justify-content: space-between;

      .moving-icon {
        display: none;
      }

      .item-image {
        height: 100%;
        width: 69px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          background-color: #f2f7fb;
        }
      }

      .item-title {
        margin-left: 12px;
      }

      .item-description {
        margin-left: 12px;
      }

      .info-wrapper-flex {
        flex-grow: 1;
        overflow: hidden;

        .item-title {
          margin-bottom: 5px;
        }
      }
    }
  }
}
