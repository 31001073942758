.about {
  position: relative;
  padding: 50px 0;

  .background-image {
    width: 100%;
    margin-bottom: 10px;

    img {
      height: 200px;
    }
  }

  .info-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .numbers-part {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
    }

    .left-part {
      width: 48%;
      margin-right: 30px;
    }

    .right-part {
      width: 48%;
    }
  }
  .list-label{
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
  }
  .items-list-wrapper{
    min-height: auto;
    margin-top: 15px;
  }

}
