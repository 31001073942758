@import "../mainColors";

.dragging-item {
  width: 30px;
}

.media-card {
  width: 12.4%;
  position: relative;
  padding: 10px;

  .media-card-wrapper {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .more-btn {
      position: absolute;
      opacity: 0;
      z-index: 10;
      top: 10px;
      right: 10px;
      transition: opacity .1s linear;
      cursor: pointer;
      border-radius: 4px;
      display: flex;

      &.visible {
        opacity: 1;
      }

      &.bg-white {
        background-color: white;
      }
    }

    .image-wrapper {
      border-radius: 8px;
      //border: 1px solid white;
      position: relative;
      width: 100%;
      padding-top: 85%;
      opacity: 1;
      transition: all 0.2s;

      .card-image {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        //border-radius: 8px 8px 0 0;

        .image {
          width: 37%;

          &.img-bg {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .folder-locked {
        position: absolute;
        z-index: 10;
        border-radius: 3px;
        top: 10px;
        right: 10px;

        i {
          font-size: 18px;
        }
      }
    }

    .extension, .size {
      display: inline-block;
      margin-top: 5px;
      padding: 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #7D7E85;
      font-size: 13px;
      font-weight: 600;
    }

    .name {
      display: inline-block;
      padding: 0 8px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 700;
    }

    &:hover {
      .more-btn {
        opacity: 1;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      border-radius: 4px;
    }
  }

  &.selected {
    .image-wrapper {
      opacity: 0.5;
    }
  }

  &.selected-card {
    .media-card-wrapper {
      //box-shadow: rgba(25, 103, 210, 1) 0 0 0 1px;

      .card-description {
        background-color: #e8f0fe;
      }
    }
  }

  &.disabled {
    .image-wrapper {
      opacity: 0.3;
    }
  }

  .checked {
    position: absolute;
    z-index: 25;
    top: 17px;
    left: 17px;
    width: 22px;
    height: 22px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

.actions-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 6px 20px;
      display: flex;
      align-items: center;

      img {
        margin-right: 16px;
      }
    }
  }
}

.media-list-card {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  width: 100%;
  margin: 0;

  .more-btn {
    opacity: 0;
    padding: 4px 10px;

    &.visible {
      opacity: 1;
    }
  }

  > span {
    display: table-cell;
    vertical-align: inherit;
    width: 13%;
    padding-left: 10px;

    &.folder-locked {
      position: relative;

      i {
        position: absolute;
        font-size: 18px;
        top: 8px;
      }
    }
  }

  .image-wrapper {
    width: 26px;
    padding: 4px 10px;

    .card-image {

      img {
        width: 26px;
        height: 26px;
        object-fit: contain;
        border-radius: 4px;
      }
    }
  }

  > span:nth-child(2) {
    width: 60%;
  }

  > span:last-child {
    width: 5%;
  }

  &:hover .more-btn {
    opacity: 1;
  }

  .checked {
    position: absolute;
    z-index: 25;
    top: 17px;
    left: 17px;
    width: 22px;
    height: 22px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  &:nth-child(even) {
    background-color: #fafafa;
  }

  &:nth-child(odd) {
    background-color: #fff;
  }

  &:hover {
    background-color: #f6f6f6;
  }

  &.selected-card {
    background-color: #e8f0fe;
  }
}

@media all and (max-width: 1600px) {
  .media-card {
    width: 16.5%;
  }
}

@media all and (max-width: 1300px) {
  .media-card {
    width: 20%;
  }
  .media-list-card {
    > span {
      width: 18%;
    }

    > span:nth-child(2) {
      width: 45%;
    }
  }
}

@media all and (max-width: 1000px) {
  .media-card {
    width: 25%;
  }
}

@media all and (max-width: 800px) {
  .media-card {
    width: 33.3%;
  }
  .media-list-card {
    > span {
      width: 20%;
    }

    > span:nth-child(2) {
      width: 36%;
    }
  }
}