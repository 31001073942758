@import "../mainColors";

.input-group {
  margin-bottom: 16px;
  position: relative;

  label {
    display: block;
    font-weight: 500;
    color: #000000;
    margin-bottom: 16px;
  }

  input, textarea, select {
    font-size: 12px;
    font-weight: 400;
    height: 35px;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    color: $textColor;

    &::placeholder {
      color: #bababa;
    }

    &:focus {
      //outline: $brandColor auto 5px;
      outline: none;
      border: 1px solid darken($borderColor, 15%);
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border: 1px solid #FF0000;
      outline: none;
      .ant-select-selector{
        border-color: #FF0000;
      }
    }

    &:disabled {
      background: #f2f7fb;
      cursor: no-drop;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  textarea {
    resize: none;
    height: 125px;
  }

  .visibility-icon {
    left: unset;
    right: 16px;
    height: 18px;
    cursor: pointer;
    transform: translateY(-50%);
    display: flex;

    &.invisible span {
      color: $brandColor;
    }
  }
  .invalid {
    border: 1px solid #FF0000;
    outline: none;
    border-radius: 4px;
  }

  //.ant-select-selection__placeholder {
  //  font-size: 12px;
  //  color: #bababa;
  //}
  //
  //.ant-select-search__field {
  //  border: none !important;
  //  padding: 6px 0 !important;
  //}
  //
  //.ant-select-selection--single {
  //  height: 100%;
  //}
  //
  //.ant-select-disabled {
  //  .ant-select-selection {
  //    background-color: #f2f7fb
  //  }
  //}
}

.select-item {
  .with-image-item {
    img {
      height: 35px;
      width:  35px;
      border-radius: 5px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 450px) {
  .input-group {
    margin-bottom: 10px;
  }
}
