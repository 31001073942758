@import "../mainColors";
.not-found .inner-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  border-left: 1px solid $borderColor;
  box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);
  height: 100%;
  min-height: calc(100vh - 85px);
  .number {
    color: $brandColor;
    font-size: 15vw;
    font-weight: 700;
    line-height: 15vw;
    display: block;
  }
  .lost-text {
    color: #0E0E10;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1500px) {
  .not-found {
    .inner-content .lost-text {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .not-found {
    .inner-content .lost-text {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .not-found {
    .inner-content {
      .number {
        font-size: 25vw;
        line-height: 25vw;
      }
      .lost-text {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .not-found {
    .inner-content {
      min-height: calc(100vh - 70px);
    }
  }
}

@media screen and (max-width: 520px) {
  .not-found {
    .inner-content {
      .lost-text {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .not-found {
    min-height: 50vh;
    .inner-content {
      .number {
        font-size: 30vw;
        line-height: 30vw;
      }
      .lost-text {
        font-size: 16px;
      }
    }
  }
}