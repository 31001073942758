@import "../mainColors";
@import "../mixins";

.admin-media {

  .infinite-loader {
    display: flex;
    width: 100%;
    justify-content: center;

    i {
      color: $brandColor !important;
    }
  }


  .media-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px 0;

    &.list-view {
      margin: 0 0 0 -30px;
      width: calc(100% + 60px);
      display: table;
      border-collapse: collapse;

      .list-header {
        display: table-header-group;
        vertical-align: middle;
        border: 1px solid $borderColor;
        border-left: none;

        > span {
          display: table-cell;
          vertical-align: inherit;
          font-weight: 600;
          padding: 5px 0;

          &:not(:nth-child(2)) {
            border-left: 1px solid $borderColor;
            padding-left: 10px;
          }

          &:first-child {
            padding-left: 20px;
            border-left: none;
          }
        }
      }
    }
  }
}

.media-title {
  //width: calc(100% - 600px);
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.small-title {
    //width: calc(100% - 800px)
  }

  @include custom-scrollbar-x;

  .path-item {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: $brandColor;
    }
  }

  .search_box {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    width: 350px;
    border: 1px solid $borderColor;
    border-radius: .25rem;
    align-items: center;
    padding: 0 12px;

    .search_btn {
      float: left;
      background-color: transparent;
      display: flex;
      align-items: center;

      i {
        font-size: 14px;
        color: grey;
      }

      svg {
        path {
          fill: $borderColor;
        }
      }
    }

    .input-group {
      display: flex;
      flex-grow: 1;
      margin-bottom: 0;
      padding: 0;
      align-items: center;

      input {
        width: 100%;
        border: 0;
        height: 32px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .admin-media {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -15px;
        width: calc(100% + 30px);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .admin-media {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -8px;
        width: calc(100% + 16px);
      }
    }
  }
  .media-title {
    flex-direction: column;
    align-items: flex-start;

    div {
      width: 100%;
    }

    .search_box {
      width: 100%;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .admin-media {
    .media-wrapper {
      &.list-view {
        margin: 0 0 0 -8px;
        width: calc(100% + 16px);
      }
    }
  }
}
