.items-list-wrapper {
  font-size: 14px;
  width: 100%;
  overflow: auto;
  min-height: 500px;

  .items-list-header {
    display: flex;
    justify-content: space-between;
    background-color: #F2F3F8;
    color: #696B76;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 4px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      left: -7px;
    }

    &.select-active {
      padding-left: 32px;
    }

    .general-info {
      display: flex;
      flex-grow: 1;

      .general-info-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        line-height: 30px;

        &:first-child {
          text-align: left;
        }
      }
    }

    .items-header-actions-part {
      display: flex;

      .status {
        line-height: 30px;
        text-align: center;
        width: 120px;
      }

      .actions {
        line-height: 30px;
        text-align: right;
        width: 150px;
      }
    }
  }

  .no-items {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 170px;
    @media screen and (max-width: 1000px) {
      padding-top: 120px;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 12px;
    .items-list-header {
      .items-header-actions-part {
        .status {
          width: 80px;
        }

        .actions {
          width: 120px;
        }
      }
    }
  }
}
