@import "../mainColors";
@import "../fonts";
@import "../mixins";

.media-uploader-wrapper-modal {
  padding: 0 20px 20px;
  width: 90vw;
  height: 85vh;
  position: relative;
}

.media-uploader-context {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: white;

  .progress {
    top: 5px;
    left: 0;
    opacity: 0;
    width: 99%;
    position: absolute;
    transition: opacity 0.2s;

    &.show {
      opacity: 1;
    }
  }

  .crop-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .aspects-wrapper {
      display: flex;
      align-items: flex-start;
      height: 50px;

      .aspect-item {
        display: flex;
        align-items: center;
        background-color: white;
        padding: 5px 10px;
        border: 1px solid black;
        color: black;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.2s;

        &.selected {
          color: $brandColor;
          border-color: $brandColor;
        }
      }
    }

    .crop-content {
      position: relative;
      height: 400px;

      .cropping {
        position: absolute;
        top: 10px;
        left: 50%;
        padding: 4px 15px;
        transform: translateX(-50%);
        z-index: 3;
        background-color: black;
        opacity: 0;
        font-size: 12px;
        border-radius: 4px;
        visibility: hidden;
        transition: visibility 0.2s;
        color: white;
        @media all and (max-width: 1000px) {
          &.show {
            opacity: 0.7;
            visibility: visible;
          }
        }

      }

      .crop-btn {
        position: absolute;
        z-index: 2;
        background-color: black;
        opacity: 0.7;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        transition: opacity 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 1;
        }

        .crop-icon {
          width: 18px;
          height: 18px;
        }
      }

      .confirm-btn {
        top: 25px;
        right: 15px;
      }

      .close-btn {
        top: 60px;
        right: 15px;
      }

      .reset-btn {
        top: 95px;
        right: 15px;
      }

      .cropper-component {
        border-radius: 6px;
      }

      .zoom-slider {
        background-color: black;
        opacity: 0.7;
        position: absolute;
        left: 10px;
        border-radius: 4px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .ant-slider-vertical {
          width: 12px;
          height: 200px;
          margin: 6px 10px;
          padding: 0 4px;

          .ant-slider-step {
            background-color: #7D7E85;
          }

          .ant-slider-handle {
            background-color: #7D7E85;
            border-color: #7D7E85;
          }
        }

        .zoom-btn {
          background-color: transparent;
          color: #7D7E85;
          font-size: 25px;
          font-family: Bold;
        }
      }
    }
  }

  .media-items-wrapper {
    width: 100%;
    max-height: calc(100% - 20px);
    display: flex;
    margin-top: 30px;
    padding-bottom: 50px;
    flex-wrap: wrap;
    overflow: auto;

    &.croppingActive {
      max-height: calc(100% - 470px);
    }

    .media-item {
      width: 150px;
      height: 170px;
      margin: 0 20px 20px 0;
      position: relative;
      display: flex;
      flex-direction: column;


      .media-item-icon {
        object-fit: cover;
        border-radius: 4px;
        width: 100%;
        height: 150px;
      }

      .item-bottom-part {
        .name {
          height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          font-size: 14px;
          margin-bottom: 12px;
        }
      }


      .btn {
        position: absolute;
        right: 0;
        background-color: white;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 6px;
        border-radius: 5px;
        visibility: hidden;
        transition: opacity 0.2s;
        opacity: 0;

        i {
          color: white;
          font-size: 16px;
        }

        img {
          vertical-align: middle;
          width: 9px;
          height: 9px;
        }
      }

      .delete-btn {
        background: #FF4E41;
        margin-bottom: 7px;
        top: 0;
      }

      .edit-btn {
        top: 32px;
        background: $brandColor;
      }


      &:hover {
        .btn {
          visibility: visible;
          opacity: 1
        }
      }
    }
  }

  .upload-buttons-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-end;

    .cancel-btn {
      margin-right: 15px;
    }
  }
}
