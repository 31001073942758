@import "../mainColors";

.static-input-row {
  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #677897;
    margin-bottom: 8px;
  }
  .flex-row {
    display: flex;
    justify-content: space-between;
    .input-group {
      width: calc(100% - 60px);
      input {
        outline: none;
      }
    }
    .input-view,.text-area-view {
      width: calc(100% - 60px);
      display: block;
      font-size: 13px;
      margin-bottom: 16px;
      padding: 10px 13px 0;
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #d8d8d8;
      border-top: 1px solid white;
    }
    .text-area-view{
      font-family: Regular;
    }
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      padding-top: 9px;
      button {
        width: 22px;
        height: 22px;
        cursor: pointer;
        border-radius: 3px;
        transition: all .2s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        i {
          font-size: 18px;
          color: white;
        }
        img, svg{
          path{
            fill: white;
          }
        }
      }
      .editing-btn {
        background: #63bf5b;
        &:hover {
          background: #2ca22c;
        }
        img{
          height: 17px;
        }
      }
      .canceling-btn {
        background: #808080;
        &:hover {
          background: #6d6d6d;
        }
        img, svg{
          height: 17px;
        }
      }
      .saving-btn {
        background: $brandColor;
        &:hover {
          background: $brandSecColor;
        }
        img, svg{
          height: 17px;
        }
      }
    }
  }
}
